<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3 pl-1">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="dashboard"
              backText="home"
              title="My Offers"
              sub-title="WHAT WE PROPOSE"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <main>
      <div>
        <v-row class="justify-content-center pb-4 wa__hiring__requests">
          <v-col cols="12" md="2"><OfferFilter /></v-col>
          <v-col cols="12" md="10"><OfferItems /></v-col>
        </v-row>
      </div>
    </main>
    <footer>
      <div class="wa__home--footer">
        <site-footer text="All Right Reserved"></site-footer>
      </div>
    </footer>
    <div class="text-center">
      <ModelOfferResponse />
      <ModelOfferResponseDetail />
      <ModelNewOffer v-if="isModal('modal_new_offer')" />
      <ModelOfferDetail />
      <ModelStatus />
    </div>
  </div>
</template>
<script>
import OfferItems from "../../components/Offers/Items.vue";
import OfferFilter from "../../components/Offers/Filter";
import ModelOfferResponse from "../../components/Offers/Modal/OfferResponseModal";
import ModelOfferResponseDetail from "../../components/Offers/Modal/OfferResponseDetailModal";
import ModelNewOffer from "../../components/Offers/Modal/NewOfferModal";
import ModelStatus from "../../components/Offers/Modal/ModalStatus.vue";
import ModelOfferDetail from "../../components/Offers/Modal/OfferDetailModal";
import {mapGetters,mapMutations} from "vuex";
export default {
  components: {
    OfferItems,
    OfferFilter,
    ModelOfferResponse,
    ModelOfferResponseDetail,
    ModelNewOffer,
    ModelOfferDetail,
    ModelStatus,
  },
  computed:{
    ...mapGetters("modal", ["isModal"]),
  },
  methods: {
    ...mapMutations('jobOffer', ['DESTROY_HISTORY_OFFERS']),
  },
  destroyed(){
    this.DESTROY_HISTORY_OFFERS()
  }
};
</script>
