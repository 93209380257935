//tinymce
import Vue from "vue";
import tinymce from "tinymce/tinymce";
import "tinymce/themes/modern";
Vue.use(tinymce);
// skin css
import "tinymce/skins/lightgray/skin.min.css";
import "tinymce/skins/lightgray/content.min.css";
//plugins
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/lists";
import "tinymce/plugins/paste";
import "tinymce/plugins/link";
import "tinymce/plugins/preview";
import "tinymce/plugins/anchor";
import "tinymce/plugins/charmap";
import "tinymce/plugins/print";
import "tinymce/plugins/image";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/directionality";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/colorpicker";
// import "tinymce/plugins/help";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/textcolor";
import "tinymce/plugins/table";
import "tinymce/plugins/media";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/code";
import "tinymce/plugins/visualblocks";
import "@/scss/plugins/tinymce.css";
import axios from 'axios';
export const initTiny = (batch_id, locale,model_name, collection_name = 'main_image') => ({
    skin: true,
    language_url: `/langs/${locale==='jp'?'ja':locale}.js`,
    language: locale==='jp'?'ja':locale,
    height: 165,
    menubar: true,
    paste_retain_style_properties: "all",
    paste_word_valid_elements: "*[*]", //word Need it
    toolbar: 'lineheight language undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
    plugins: 'lineheight language colorpicker textcolor print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
    // 'fontselect'
    // font_formats : "IranYekan=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace;AkrutiKndPadmini=Akpdmi-n",
    fontsize_formats: '10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 36pt 60pt 72pt',
    images_upload_url: "/api/files",
    relative_urls : false,
    remove_script_host : false,
    document_base_url: window.location.origin,
    images_upload_credentials: true,
    automatic_uploads: false,
    file_picker_types: "file image media",
    content_style: 'body { font-family: "Arial", "Montserrat" !important; } br { content: " "; display: block; margin: 0.25em 0; } p { margin-block-start: 0; margin-block-end: 0; margin-inline-start: 0px; margin-inline-end: 0px; padding:0; margin:0 !Important; }',
    images_upload_handler: async function(blobInfo, success, failure) {
        let formData = new FormData();
        formData.append("file", blobInfo.blob(), blobInfo.filename());
        formData.append("batch_id", batch_id);
        formData.append("model_name", model_name);
        formData.append("collection_name", collection_name);
        let response = await axios.post('/api/files', formData);

        if (response && response.status == 200) {
            return success(response.data.data.url);
        } else {
            return failure("HTTP Error: " + response.status);
        }
    }
});
