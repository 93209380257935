<template>
  <basic-modal @open="setData" width="1200" transition="slide-x-transition">
    <template #header>
      <divider
        title="Offer Details"
        sub-title="the details you need"
        size="large"
      ></divider>
    </template>
    <template #default>
      <div class="mt-4 px-10 pr-13">
        <div class="d-flex justify-center mb-10">
          <!-- <div
            style="background-image: url(https://via.placeholder.com/600x250)"
          ></div> -->
          <img :src="details.image" class="rounded offer__detail--image" alt="" />
        </div>
        <SectionTitle title="basic info" />
        <v-row>
          <v-col cols="3"
            ><RequestDetailsInformation
              class="mr-0"
              title="title"
              :subTitle="details.title"
          /></v-col>
          <v-col cols="2"
            ><RequestDetailsInformation
              class="mr-0"
              title="location"
              :subTitle="details.location"
          /></v-col>
          <v-col cols="4"
            ><RequestDetailsInformation
              class="mr-0"
              title="nearest station"
              :subTitle="details.nearest_station"
          /></v-col>
          <v-col cols="3"
            ><RequestDetailsInformation
              class="mr-0"
              title="OFFER SALARY"
              :subTitle="details.offer_salary + '$'"
          /></v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="request__details__information d-flex">
              <div class="d-block ml-1">
                <div class="request__details__information--title">dates</div>
                <div class="request__details__information--sub-title">
                  <v-chip v-for="date in details.dates" :key="date.id" class="dates__chip mr-1" color="grey lighten-3" x-small label
                    >{{date.date}}, {{date.begin_time}} to {{date.end_time}} {{date.require ? '- Required' : ''}}
                  </v-chip>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <ul class="d-flex pl-0 justify-space-between mt-2">
          <!-- <RequestDetailsInformation title="Client Name" :subTitle="details.user.name" />
          <RequestDetailsInformation
            title="Client Email"
            :subTitle="details.user.email"
          /> -->

          <!-- <RequestDetailsInformation title="begin date" :subTitle="details.begin_date" />
          <RequestDetailsInformation title="end date" :subTitle="details.end_date" /> -->
        </ul>
        <ul class="list-unstyled pl-0 d-flex">
          <li class="request__details__information d-flex">
            <div class="d-block ml-1">
              <div class="request__details__information--title">Description</div>
              <div
                v-html="details.description"
                class="request__details__information--sub-title"
              ></div>
            </div>
          </li>
        </ul>
        <v-row>
          <v-col cols="3"
            ><RequestDetailsInformation title="Client Name" :subTitle="details.user.name"
          /></v-col>
          <v-col cols="9">
            <RequestDetailsInformation
              title="Client Email"
              :subTitle="details.user.email"
            />
          </v-col>
        </v-row>
        <div class="mt-4 pt-1">
          <SectionTitle title="STATUS" />
          <ul class="d-flex mb-0 pl-0 mt-2">
            <RequestDetailsInformation
              title="total RESPONSE"
              :subTitle="details.total_response_count"
            />
            <RequestDetailsInformation
              title="total approved"
              :subTitle="details.approved_response_count"
            />
            <RequestDetailsInformation
              title="total reject"
              :subTitle="details.reject_response_count"
            />
          </ul>
        </div>
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-3 pt-2 pb-2">
        <block-button
          class="btn__size__14 btn__modal--assign mr-5 site__button width-auto"
          text="IT'S ALLRIGHT, THANK YOU"
          @click.native="$_closeModal()"
          height="30"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import SectionTitle from "../../Global/Section/SectionTitle.vue";
import RequestDetailsInformation from "../../Global/Section/RequestDetailsInformation.vue";
import JobOfferRespository from "@/abstraction/repository/jobOfferRepository";
let jobOfferRepository = new JobOfferRespository();
export default {
  name: "modal_offer_detail",
  components: { SectionTitle, RequestDetailsInformation },
  data() {
    return {
      details: {
        user: {},
        dates : []
      },
      offerId: null,
    };
  },
  methods: {
    async loadDetailsData() {
      this.details = await jobOfferRepository.showJobOffer(this.offerId);
    },
    setData({ model }) {
      this.offerId = model.id;
      this.loadDetailsData();
    },
  },
};
</script>
<style scoped>
.offer__detail--image {
  max-width: 367px;
  max-height: 434px;
}
.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
}
.btn-more {
  font-family: "Barlow-EL", sans-serif;
  font-size: 11px;
  padding: 13px 10px !important;
}
.request__details__information::before {
  height: 24px;
  background-color: var(--color-light-gray);
  content: "";
  display: block;
  width: 1px;
  margin: 7px 2px 0 0;
}
.request__details__information--title {
  color: var(--color-smoky);
  font-size: 12px;
  font-family: "Montserrat-ExtraLight";
  text-transform: uppercase;
}
.request__details__information--sub-title {
  font-size: 15px;
  font-family: "Montserrat";
  color: var(--color-black);
  position: relative;
  top: -0.3rem;
}
.dates__chip {
  font-size: 14px;
  font-family: "Montserrat-light";
}
</style>
<style>
ul li:last-child {
  margin: 0 !important;
}
</style>
