<template>
    <v-row class="offer__card border rounded mx-0 mt-0">
        <v-col cols="4">
            <div class="d-flex align-center h-100" style="overflow: hidden">
                <div class="name__base">
                    <div class="offer__name">
                        {{ offer.title }}
                    </div>
                    <small class="offer__issued"> ISSUED ON {{ offer.created_at }}</small>
                </div>
            </div>
        </v-col>
        <v-col>
            <div class="d-flex align-center h-100">
                <div style="line-height: 22px">
                    <div>
                        <block-button
                                color="black"
                                :text="`${offer.jobOfferResponsesCount} model responsed`"
                                class="wa__f__m__r btn__offer__response"
                                height="17"
                        ></block-button>
                    </div>
                    <div>
                        <block-button
                                :color="acceptedStatus ? 'cyan' : 'red'"
                                :bg-color="acceptedStatus ? 'bgCyan' : 'bgRed'"
                                :text="offer.status"
                                class="btn__offer__status"
                                height="23"
                                @click.native="$_openModal('changeStatus', { model: offer })"
                        ></block-button>
                    </div>
                </div>
            </div>
        </v-col>
        <v-col cols="4">
            <div class="d-flex align-center h-100 justify-content-end">
                <div class="mr-3">
                    <circle-button
                            color="black"
                            height="35"
                            width="48"
                            icon="WMi-pencil"
                            @click.native="$_openModal('new_offer', { model: offer })"
                            size="large"
                            :disabled="offer.jobOfferResponsesCount > 0"
                    ></circle-button>
                    <circle-button
                            color="cyan"
                            bg-color="bgCyan"
                            height="35"
                            width="48"
                            icon="WMi-users"
                            @click.native="$_openModal('offer_response', { model: offer })"
                            size="large"
                    />
                    <!-- <router-link :to="{ name: 'profile' }"> -->
                    <circle-button
                            color="black"
                            height="35"
                            width="48"
                            size="large"
                            icon="WMi-link"
                            @click.native="copyLinkToClipboard"
                    />
                    <!-- </router-link> -->
                    <circle-button
                            color="black"
                            height="35"
                            width="48"
                            icon="WMi-align-left"
                            size="large"
                            @click.native="$_openModal('offer_detail', { model: offer })"
                    />
                    <circle-button
                            color="red"
                            bg-color="bgLightPink"
                            height="35"
                            width="48"
                            icon="WMi-trash"
                            size="large"
                            @click.native="deleteMessage"
                    />
                </div>
            </div>
        </v-col>
    </v-row>
</template>
<script>
    import BlockButton from "../Global/Button/BlockButton.vue";
    import toast from "@/utils/toast";
    import {mapActions} from "vuex";

    export default {
        components: {BlockButton},
        props: {
            offer: {
                type: Object,
            },
        },
        methods: {
            ...mapActions("jobOffer", ["deleteJobOffer"]),
            deleteMessage() {
                toast.question(this.$_trans("toast.del_offer_item_msg"), this.$_trans("toast.del_offer_item_title"), () => {
                    this.deleteJobOffer(this.offer.id);
                });
            },
            copyLinkToClipboard() {
                const textToCopy = `${window.location.origin}/en/modeling-offer/${this.offer.unique_id}`;
                // navigator clipboard api needs a secure context (https)
                if (navigator.clipboard && window.isSecureContext) {
                    // navigator clipboard api method'
                    navigator.clipboard.writeText(textToCopy);
                    toast.success(this.$_trans("toast.copy_link_msg"), this.$_trans("toast.copy_link_title"));
                } else {
                    // text area method
                    let textArea = document.createElement("textarea");
                    textArea.value = textToCopy;
                    // make the textarea out of viewport
                    textArea.style.position = "fixed";
                    textArea.style.left = "-999999px";
                    textArea.style.top = "-999999px";
                    document.body.appendChild(textArea);
                    textArea.focus();
                    textArea.select();
                    new Promise((res, rej) => {
                        // here the magic happens
                        document.execCommand('copy') ? res() : rej();
                        textArea.remove();
                    });
                    toast.success(this.$_trans("toast.copy_link_msg"), this.$_trans("toast.copy_link_title"));
                }
            },
        },
        computed: {
            acceptedStatus() {
                return this.offer.status === "onhold" || this.offer.status === "ongoing";
            },
        },
    };
</script>
<style scoped>
    .name__base {
        line-height: 16px;
        margin-left: 25px;
    }

    .offer__offer__text {
        font-family: "montserrat-light";
        font-size: 20px;
        width: min-content;
        color: var(--color-gray);
        line-height: 18px;
    }

    .offer__name {
        font-family: "montserrat-regular";
        font-size: 20px;
        text-transform: uppercase;
        letter-spacing: 2px;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-wrap: break-word;
    }

    .offer__issued {
        position: relative;
        font-family: "montserrat-regular";
        font-size: 10px;
    }

    .btn__offer__response {
        font-size: 12px;
    }

    .btn__offer__status {
        font-size: 14px;
    }
</style>
