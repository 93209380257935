<template>
  <div>
    <SectionTitle title="responses" />
    <hr class="mt-0" />
    <template>
      <v-row class="px-15 pb-1 d-none d-md-flex">
        <v-col cols="4" class="wa__offer__title--title pl-12">
          <div class="d-flex">
            <Checkbox
              class="d-inline-flex ml-1"
              @change="toggleSelectAll"
              name="checkbox"
              id="checkbox"
              v-model="isAllResponseSelected"
            />
            <span class="ml-3">NAME / CELL PHONE</span>
          </div>
        </v-col>
        <v-col cols="4" class="wa__offer__title--title text-center">
          <span>status</span>
        </v-col>
        <v-col
          cols="4"
          class="wa__offer__title--title text-right justify-content-end"
        >
          <span>controls</span>
        </v-col>
      </v-row>
      <data-iterator
        :items="getResponse"
        :is-filtered="isFilteredResponse"
        @pagination="changePagination"
        :pagination="getPaginationResponse"
        :loading="getLoadingResponse"
      >
        <template v-slot:loading>
          <loading />
        </template>
        <template #no-items>
          <no-items />
        </template>
        <template #no-results>
          <no-items />
        </template>

        <template v-slot:items="{ item }">
          <OfferResponseItem :key="item.id" :response-offer="item" class="mb-2" />
        </template>
      </data-iterator>
      <!-- --------------------------------------------------------------------
                            Modals :: BEGIN
      ---------------------------------------------------------------------->
      <div class="text-center">
        <sendEmailModal
          v-if="isModal('modal_sendEmail')"
          :job_offer_id="job_offer_id"
        />
        <emailConfirmationModal
          v-if="isModal('modal_emailConfirmation')"
          :filters="getResponseFilters"
        />
      </div>
      <!-- --------------------------------------------------------------------
                                    Modals :: END
      ---------------------------------------------------------------------->
    </template>
    <!-- <template v-else>
      <div class="text-center">There are no items to display</div>
    </template> -->
  </div>
</template>
<script>
import SectionTitle from "../../../Global/Section/SectionTitle.vue";
import OfferResponseItem from "./Item.vue";
import SendEmailModal from "./SendEmail.vue";
import emailConfirmationModal from "@/components/Global/Modal/EmailConfirmation.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  components: {
    OfferResponseItem,
    SectionTitle,
    SendEmailModal,
    emailConfirmationModal,
  },
  data() {
    return {
      isAllResponseSelected: [],
    };
  },
  props: ["job_offer_id"],
  computed: {
    ...mapGetters("jobOffer", [
      "getResponse",
      "getPaginationResponse",
      "getResponseFilters",
      "getLoadingResponse",
      "isFilteredResponse",
    ]),
    ...mapGetters("modal", ["isModal"]),
  },
  methods: {
    ...mapActions("jobOffer", ["loadResponses", "toggleSelectAll"]),
    ...mapMutations("jobOffer", ["SET_PAGINATION_RESPONSE"]),
    changePagination(pagination) {
      if (this.getPaginationResponse.page !== pagination.page) {
        this.SET_PAGINATION_RESPONSE(pagination);
        this.loadResponses(this.job_offer_id);
      }
    },
  },
  created() {
    this.loadResponses(this.job_offer_id);
  },
};
</script>
<style scoped>
.wa__offer__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  height: 16px;
}
.wa__offer__title--title {
  font-family: "Montserrat";
  font-size: 0.6rem;
  color: var(--color-light-gray);
  text-transform: uppercase;
  letter-spacing: 1px;
}
</style>
