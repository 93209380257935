<template>
  <v-autocomplete
    :items="clients"
    color="SonicSilver"
    v-model="client"
    item-text="name"
    :label="label"
    append-icon="WMi-search"
    @keyup="filterClients"
    :loading="isLoading"
    return-object
    dense
    outlined
    hide-no-data
    no-filter
    :rules="rules"
  >
    <template>
      <v-row class="border-bottom border-light-gray">
        <v-col
          xl="9"
          md="9"
          class="pa-1 flex-justified-right"
          v-for="client in clients"
          :key="client.id"
        >
          <Name :title="client.name" :sub-title="client.cell_phone" />
        </v-col>
      </v-row>
    </template>
  </v-autocomplete>
</template>

<script>
import debouncedFunction from "@/utils/filter/autocompleteFilter.js";
import Name from "@/components/Global/Section/Name.vue";
import ClientRepository from "@/abstraction/repository/clientRepository";
const repository = new ClientRepository();
export default {
  components: {
    Name,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
      default: () => ("search client"),
    },
    rules:{
      default: () => ([])
    }
  },
  data() {
    return { clients: [], client: this.value, isLoading: false };
  },
  methods: {
    filterClients(event) {
      debouncedFunction(event, async () => {
        let filters = {
          name: {
            type: "like",
            val: event.target.value,
          },
        };
        let params = {
          filters,
          pagination: {
            page: 1,
          },
        };
        try {
          const response = await repository.index(params);
          this.clients = response.data;
        } catch (err) {
          console.log(err, "there was an error loading clients");
        } finally {
          this.isLoading = false;
        }
      });
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.client = this.value;
      },
    },
    client: {
      deep: true,
      immediate: true,
      async handler() {
        if (
          this.client &&
          this.client.id &&
          !this.clients.find((c) => c.id === this.client.id)
        ) {
          this.clients.push(this.client);
        }

        this.$emit("input", this.client);
      },
    },
  },
};
</script>
