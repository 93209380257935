<template>
  <v-row
    v-if="getResponseSelected.length"
    class="section-bordered theme-cyan ma-0 wrap border py-1"
  >
    <v-expansion-panels v-model="panel" :readonly="readonly" multiple>
      <v-expansion-panel>
        <!-- <v-expansion-panel-header>
                    <v-row class="ma-0 pt-2">
                        <v-col lg6>
                            <v-badge color="red" right>
                                <template v-slot:badge>
                                    <span> {{getResponseSelected.length}} </span>
                                </template>
                                <h3> Response selected </h3>
                                <div class="gray--text mt-2"> choice action
                                </div>
                            </v-badge>
                        </v-col>
                        <v-col lg6 xs12 class="flex-justified-left" style="padding-left: 52px;">
                        </v-col>
                    </v-row>
                </v-expansion-panel-header> -->
        <v-expansion-panel-header hide-actions>
          <div class="d-flex justify-space-between align-center">
            <div>
              <!-- <v-badge color="red" right>
                                <template v-slot:badge>
                                    <span> {{getResponseSelected.length}} </span>
                                </template>
                                <h3> models selected </h3>
                                <div class="gray--text mt-2"> choice action
                                </div>
                            </v-badge> -->
              <v-chip
                class="chip__models__selected w-100"
                label
                text-color="black"
                data-toggle="collapse"
                data-target="#responseSelected"
                aria-labelledby="responseSelected"
              >
                <div>
                  <span>{{ getResponseSelected.length }}</span> Response selected
                </div>
                <div>
                  <v-icon small color="#707070" class="WMi-down-open"></v-icon>
                </div>
              </v-chip>
            </div>
            <div class="justify-content-end" style="padding-left: 52px">
              <circle-button icon="WMi-ok" color="cyan" bg-color="bgCyan" />
              <circle-button
                icon="WMi-mail-alt"
                color="black"
                @click.native="$_openModal('sendEmail', { model: getResponseSelected })"
              />

              <!-- <block-button
                height="30"
                class="btn__modal--assign ml-5"
                text="send email"
                icon="WMi-message-reply-text"
                color="red"
                bg-color="bgRed"
                @click.native="
                  $_openModal('sendEmail', { model: getResponseSelected })
                "
              ></block-button> -->
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-chip
            class="ma-1"
            v-for="(response, i) in getResponseSelected"
            v-bind:key="i"
            @click:close="REMOVE_RESPONSE_SELECTED(response.id)"
            close
            close-icon="WMi-cancel"
          >
            {{ response.user.name }}
          </v-chip>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data: function () {
    return {
      panel: [1, 1],
      readonly: false,
    };
  },
  computed: {
    ...mapGetters("jobOffer", ["getResponseSelected"]),
  },
  methods: {
    ...mapMutations("jobOffer", ["REMOVE_RESPONSE_SELECTED"]),
  },
};
</script>

<style lang="scss">
.Filters {
  margin: 20px 0px 10px 0px;
}
.section-bordered {
  border-radius: 10px;
}
.chip__models__selected {
  height: 42px !important;
  width: 431px !important;
}
.chip__models__selected {
  background-color: #e6e6e680 !important;
  text-transform: uppercase;
}
.chip__models__not__selected {
  background-color: #ee355113 !important;
}
.chip__models__selected .v-chip__content {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  padding: 0 20px !important;
}
.chip__models__selected .v-chip__content div {
  font-family: "montserrat-regular" !important;
  font-size: 14px;
  letter-spacing: 2px !important;
}
.chip__models__selected .v-chip__content div span {
  font-family: "montserrat";
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
.v-expansion-panel-header {
  padding: 10px !important;
}
</style>
