<template>
  <div
    :class="[
      'offer__card  rounded',
      responseOffer.status.id === 3 ||
      responseOffer.status.id === 5 ||
      responseOffer.status.id === 1
        ? 'accepted__client'
        : responseOffer.status.id === 2 ||
          responseOffer.status.id === 4 ||
          responseOffer.status.id === 6
        ? 'rejected__client'
        : 'border',
    ]"
  >
    <v-row>
      <v-col cols="4">
        <v-row>
          <v-col cols="3">
            <div class="wa__model__name--image">
              <figure>
                <img
                  v-if="responseOffer.user.model.thumbnail"
                  :src="responseOffer.user.model.thumbnail"
                  alt="image"
                />
                <img v-else src="https://via.placeholder.com/55x65" alt="image" />
              </figure>
            </div>
          </v-col>
          <v-col cols="9" class="pl-0">
            <div class="d-flex align-center h-100" style="overflow: hidden">
              <Checkbox
                class="ml-3 mr-2 mt-n1"
                name="checkbox"
                v-model="getResponseSelected"
                :item="responseOffer"
                @change="changeResponseSelected"
              />
              <div style="line-height: 16px">
                <div class="offer__name">
                  {{ responseOffer.user.name }}
                </div>
                <small class="offer__cell_phone">{{
                  responseOffer.user.cell_phone
                }}</small>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="5">
        <div class="d-flex align-center justify-center h-100">
          <div class="mr-1">
            <div>
              <v-chip label large class="px-0 py-0">
                <div
                  :class="`px-2 py-3 offer__response--proccess--chip color-${responseOffer.status.style.color} bg-color-${responseOffer.status.style.bgColor}`"
                >
                  <small class="offer__response--step">step</small>
                  <div class="offer__response--proccess">
                    <span>
                      {{
                        responseOffer.status.id === 1 || responseOffer.status.id === 2
                          ? 1
                          : responseOffer.status.id === 3 || responseOffer.status.id === 4
                          ? 2
                          : responseOffer.status.id === 5 || responseOffer.status.id === 6
                          ? 3
                          : 4
                      }}
                    </span>
                    of
                    <span>4</span>
                  </div>
                </div>
              </v-chip>
            </div>
          </div>
          <div style="line-height: 22px">
            <div>
              <block-button
                :color="responseOffer.status.style.color"
                :bg-color="responseOffer.status.style.bgColor"
                :text="responseOffer.status.name"
                height="32"
                class="status pl-6 pr-11"
              ></block-button>
            </div>
            <div v-if="responseOffer.shotting_date">
              <block-button
                :color="responseOffer.status.style.color"
                :bg-color="responseOffer.status.style.bgColor"
                :text="responseOffer.shotting_date"
                height="19"
                class="wa__st px-5 py-2"
              ></block-button>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="d-flex align-center h-100 justify-content-end">
          <div class="mr-3">
            <circle-button
              v-if="
                (responseOffer.status.verify &&
                responseOffer.status.verification_permission === 'model') ||
                (!responseOffer.status.verify &&
                responseOffer.status.verification_permission === 'manager')
              "
              color="cyan"
              bg-color="bgCyan"
              height="35"
              width="48"
              icon="WMi-ok"
              size="large"
              @click.native="changeStatus(3)"
            />
            <circle-button
              v-if="
                (responseOffer.status.verify &&
                responseOffer.status.verification_permission === 'model')||
                (responseOffer.status.verify &&
                   responseOffer.status.verification_permission === 'manager')
              "
              color="red"
              bg-color="bgLightPink"
              height="35"
              width="48"
              icon="WMi-cancel"
              size="large"
              @click.native="changeStatus(4)"
            />
            <circle-button
              color="black"
              height="35"
              width="48"
              size="large"
              icon="WMi-mail-alt"
              @click.native="$_openModal('sendEmail', { model: responseOffer })"
            />
            <v-badge
              v-if="responseOffer.comment || responseOffer.is_change_image"
              color="red"
              icon="mdi-asterisk"
              class="badge__circle__button"
            >
              <circle-button
                color="black"
                height="35"
                width="48"
                icon="WMi-align-left"
                size="large"
                @click.native="
                  $_openModal('offer_response_detail', { model: responseOffer })
                "
              />
            </v-badge>
            <circle-button
              v-else
              color="black"
              height="35"
              width="48"
              icon="WMi-align-left"
              size="large"
              @click.native="
                $_openModal('offer_response_detail', { model: responseOffer })
              "
            />
            <circle-button
              color="red"
              bg-color="bgLightPink"
              height="35"
              width="48"
              icon="WMi-trash"
              size="large"
              @click.native="deleteResponseMessage"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import toast from "@/utils/toast";
export default {
  props: {
    responseOffer: {
      type: Object,
    },
  },
  methods: {
    ...mapActions("jobOffer", [
      "changeStatusResponse",
      "deleteResponse",
      "addToSelected",
      "removeFromSelected",
    ]),
    changeStatus(statusId) {
      this.responseOffer.newResponseId = statusId;
      this.changeStatusResponse(this.responseOffer);
    },
    deleteResponseMessage() {
      toast.question(this.$_trans("toast.del_offer_response_msg"),this.$_trans("toast.del_offer_response_title"), () => {
        this.deleteResponse(this.responseOffer.id);
      });
    },
    changeResponseSelected() {
      const index = this.getResponseSelected.findIndex(
        (x) => x.id === this.responseOffer.id
      );
      if (index >= 0) {
        this.addToSelected(this.responseOffer);
      } else {
        this.removeFromSelected(this.responseOffer);
      }
    },
  },
  computed: {
    ...mapGetters("jobOffer", ["getResponseSelected"]),
  },
};
</script>
<style lang="scss" scoped>
.offer__card {
  display: flex;
  align-items: center;
}
.rejected__client {
  border: 1px solid #ee3552;
  background-color: #ee35510a;
}
.accepted__client {
  border: 1px solid var(--color-cyan);
  background-color: #32cad50c;
}
.model__offer__img {
  height: 87px;
  background-size: cover;
  background-position: center;
  margin-left: -1px;
  border-radius: 3px;
}
.offer__name {
  font-family: "montserrat-regular";
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}
.offer__cell_phone {
  position: relative;
  font-family: "montserrat-regular";
  font-size: 10px;
}
.offer__response--step {
  font-size: 10px;
  font-family: "Montserrat-Light";
}
.offer__response--proccess {
  font-family: "Montserrat-Light";
  font-size: 13px;
  letter-spacing: -1px;
}
.offer__response--proccess span {
  font-size: 28px;
  font-family: "Montserrat-regular";
}
.status {
  font-family: "montserrat-Light";
  font-size: 18px;
}
.wa__st {
  font-family: "montserrat-regular";
  font-size: 16px;
}
.wa__model__name--image {
  height: 87px;
}

.wa__model__name--image figure img {
  height: 87px;
  width: auto;
  border-radius: 4px;
}

@import "@Styles/setup/variables";
@each $color, $value in $allColors {
  .offer__response--proccess--chip.color-#{ "" + $color} {
    color: $value !important;
  }
  .offer__response--proccess--chip.bg-color-#{ "" + $color} {
    background-color: $value !important;
  }
}
</style>
<style>
.badge__circle__button .v-badge__wrapper .v-badge__badge {
  inset: auto auto calc(100% - 16px) calc(100% - 18px) !important;
}
</style>
