<template>
  <basic-modal @open="setData" width="1500" transition="slide-x-transition">
    <template #header>
      <divider title="New Offer" sub-title="the details you need" size="large"></divider>
    </template>
    <template #default>
      <v-form ref="newOfferForm">
        <div class="mt-4 px-6 px-13">
          <v-row>
            <v-col cols="6">
              <div class="upload__image__container">
                <div class="upload__image text-center">
                  <image-cropper
                    :stencilProps="{ aspectRatio: 1280 / 720, checkImageOrigin: false }"
                    :url.sync="form.thumbnail"
                    v-model="fileForm"
                    label="Choose an image"
                  />
                </div>
              </div>
            </v-col>
            <v-col cols="6">
              <div>
                <v-text-field
                  label="OFFER TITLE"
                  color="SonicSilver"
                  class="no-error-msg"
                  v-model="form.title"
                  dense
                  :rules="requiredRules"
                  outlined
                ></v-text-field>
              </div>
              <v-card v-for="(date, i) in form.dates" :key="i" outlined class="mt-3">
                <v-row class="mt-1 mx-0 mb-0">
                  <v-col cols="12" sm="3" class="pr-1 pl-3 pb-0 pt-1">
                    <date-input
                      label="date"
                      color="SonicSilver"
                      dense
                      outlined
                      :allowedDates="allowedDate"
                      v-model="form.dates[i].date"
                      :rules="requiredRules"
                    ></date-input>
                  </v-col>
                  <v-col cols="12" sm="3" class="px-0 pb-0 pt-1">
                    <time-input
                      label="begin time"
                      color="SonicSilver"
                      dense
                      outlined
                      :max="form.dates[i].end_time"
                      v-model="form.dates[i].begin_time"
                      :rules="requiredRules"
                    ></time-input>
                  </v-col>
                  <v-col cols="12" sm="3" class="pl-1 pr-2 pb-0 pt-1">
                    <time-input
                      label="end time"
                      color="SonicSilver"
                      dense
                      outlined
                      :min="form.dates[i].begin_time"
                      v-model="form.dates[i].end_time"
                      :rules="requiredRules"
                    ></time-input>
                  </v-col>
                  <v-col cols="12" sm="3" class="pl-1 pr-2 pb-0 pt-1">
                    <div>
                      <v-checkbox
                        color="black"
                        large
                        class="checkbox--check mt-0"
                        v-model="form.dates[i].require"
                      >
                        <template v-slot:label>
                          <div class="checkbox-label">Required</div>
                        </template>
                      </v-checkbox>
                    </div>
                  </v-col>
                </v-row>
                <div class="text-right mr-5 mb-2">
                  <block-button
                    text="remove"
                    height="23"
                    color="red"
                    bg-color="bgLightPink"
                    class="btn-remove"
                    @click.native="removeDateRow(i)"
                  />
                </div>
              </v-card>
              <div class="text-right mr-5 mt-2">
                <block-button
                  text="add"
                  height="23"
                  color="cyan"
                  bg-color="bgCyan"
                  class="btn-add px-7"
                  @click.native="addDateRow"
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-if="showEditor">
              <editor v-model="form.description" :init="init"></editor>
            </v-col>
          </v-row>
          <div class="mt-3">
            <v-row>
              <v-col class="pr-0" cols="4">
                <v-text-field
                  label="offer approximate salary - Japan Yen"
                  color="SonicSilver"
                  dense
                  outlined
                  class="pt-0"
                  append-icon="WMi-money-copy"
                  v-model="form.offer_salary"
                  :rules="numericalRules"
                ></v-text-field>
              </v-col>
              <v-col class="pr-0" cols="4">
                <v-text-field
                  label="location"
                  color="SonicSilver"
                  class="pt-0 no-error-msg"
                  v-model="form.location"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Nearest station"
                  color="SonicSilver"
                  class="pt-0 no-error-msg"
                  v-model="form.nearest_station"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="mt-3">
            <v-row>
              <v-col cols="4" class="pt-0 pr-0">
                <AutoCompleteClient
                  class="pr"
                  :rules="requiredRulesClient"
                  v-model="form.user"
                />
              </v-col>
              <v-col cols="4" class="pr-0">
                <v-switch v-model="form.audition_required" inset color="black" class="social__switch mt-0 pt-0">
                  <template v-slot:label>Audition is required</template>
                </v-switch>
              </v-col>
            </v-row>
          </div>
          <div class="pb-4">
            <SectionTitle title="required files" />
            <v-row>
              <v-col cols="2">
                <v-switch
                  v-model="form.has_required_files"
                  inset
                  color="black"
                  class="social__switch"
                >
                  <template v-slot:label>active</template>
                </v-switch>
              </v-col>
              <v-col cols="7">
                <v-text-field
                  label="lable"
                  color="SonicSilver"
                  class="mt-3 no-error-msg"
                  v-model="form.required_files_label"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="pl-0">
                <v-text-field
                  label="max files count"
                  color="SonicSilver"
                  class="mt-3 no-error-msg"
                  v-model="form.required_files_max_count"
                  type="number"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="pb-4">
            <SectionTitle title="questions" />
            <v-card
              v-for="(question, i) in form.questions"
              :key="question.key"
              outlined
              class="px-3 mt-2"
            >
              <v-row class="ma-0">
                <v-col cols="2" class="px-0">
                  <v-autocomplete
                    class="pt-0 mt-0 no-error-msg"
                    item-value="id"
                    item-text="name"
                    :items="inputTypes"
                    v-model="form.questions[i].type"
                    label="type"
                    color="SonicSilver"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="9" class="pr-1">
                  <v-text-field
                    label="question"
                    class="pt-0 mt-0 no-error-msg"
                    v-model="form.questions[i].name"
                    dense
                    outlined
                    color="SonicSilver"
                  ></v-text-field>
                </v-col>
                <v-col cols="1" class=" pl-2 pr-0">
                  <div class="d-flex justify-center align-center h-100">
                    <block-button
                      text="remove"
                      height="23"
                      color="red"
                      bg-color="bgLightPink"
                      class="btn-remove w-100"
                      @click.native="removeQuestionRow(i)"
                    />
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="12">
                  <v-combobox
                    v-if="form.questions[i].type==='select'"
                    clearable
                    :append-icon="null"
                    hide-selected
                    label="values"
                    outlined
                    color="SonicSilver"
                    dense
                    multiple
                    persistent-hint
                    small-chips
                    v-model="form.questions[i].values"
                  >
                    <template #selection="{ item }">
                      <v-chip
                        close
                        class="mt-1 chip__value"
                        text-color="black"
                        label
                        small
                      >
                        {{ item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-card>
            <div class="text-right mr-5 mt-2">
              <block-button
                text="add"
                height="23"
                color="cyan"
                bg-color="bgCyan"
                class="btn-add px-7"
                @click.native="addQuestionRow"
              />
            </div>
          </div>
        </div>
      </v-form>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-5 pt-2 pb-3 d-flex justify-space-between align-items-center w-100">
        <block-button
          class="btn__modal--cancel btn__size__18"
          text="cancel"
          icon="WMi-cancel-linear1"
          text-mode="text"
          @click.native="$_closeModal()"
        ></block-button>
        <block-button
          height="37"
          class="btn__modal--assign btn__size__16 ml-5 px-13 site__button width-auto"
          :text="`${form.id ? 'update' : 'add'} the Offer`"
          icon="WMi-plus-linear"
          :loading="uploadLoading"
          @click.native="addOffer"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import {requiredRules,numericalRules,requiredRulesClient} from "@/mixins/validate"
import Editor from "@tinymce/tinymce-vue";
import AutoCompleteClient from "@/components/Global/Input/AutocompleteClient.vue";
import ImageCropper from "@/components/Global/Input/ImageCropper";
import { makeid } from "@/utils/math";
import { initTiny } from "@/plugins/tinymce/tinymce";
import { cloneDeep } from "lodash";
import FileRepository from "@/abstraction/repository/FileRepository";
import { mapActions } from "vuex";
import JobOfferRepository from "@/abstraction/repository/jobOfferRepository";
import SectionTitle from "@/components/Global/Section/SectionTitle.vue";
import Vue from "vue";
const jobOfferRepository = new JobOfferRepository();
const RANDOM_TOKEN = makeid(50);
const BLANK_QUESTION = () => ({ type: null, name: null, values : null , key : Math.random().toString(36).substring(2, 7)  })
var formDefault = {
  thumbnail: "https://via.placeholder.com/1280x720",
  file_batch_id: RANDOM_TOKEN,
  tinymce_batch_id: makeid(50),
  description: "",
  dates: [{ begin_time: null, end_time: null, date: null, require: false }],
  questions: [BLANK_QUESTION()],
};
export default {
  name: "modal_new_offer",
  components: {
    AutoCompleteClient,
    SectionTitle,
    ImageCropper,
    Editor,
  },
  data() {
    return {
      form: cloneDeep(formDefault),
      init: initTiny(formDefault.tinymce_batch_id,this.$_getLocale()),
      uploadLoading: false,
      fileForm: {
        batch_id: RANDOM_TOKEN,
        model_name: "jobOffer",
        collection_name: "main_image",
        crop: {},
      },
      showEditor: false,
      today: new Date().toISOString().substr(0, 10),
      inputTypes : ["input","select"]
    };
  },
  methods: {
    ...mapActions("jobOffer", ["storeJobOffer", "updateJobOffer"]),
    setData({ model }) {
      if (model) {
        this.loadJobOffer(model.id);
      }
    },
    async loadJobOffer(jobOfferId) {
      const jobOffer = await jobOfferRepository.showJobOffer(jobOfferId);
      this.form = { ...this.form, ...jobOffer };
    },
    async addOffer() {
      try {
        if (!this.$refs.newOfferForm.validate()) {
          return;
        }
        this.uploadLoading = true;
        if (this.fileForm.file) {
          let repository = new FileRepository();
          await repository.store(this.fileForm);
        }
        if (this.form.id) {
          await this.updateJobOffer(this.form);
        } else {
          await this.storeJobOffer(this.form);
        }
        this.$_closeModal();
      } catch (e) {
        return e;
      } finally {
        this.uploadLoading = false;
      }
    },
    allowedDate(date) {
      if (this.today <= date) {
        return date;
      }
    },
    addDateRow() {
      this.form.dates.push({
        begin_time: null,
        end_time: null,
        date: null,
        require: null,
      });
    },
    removeDateRow(index) {
      Vue.delete(this.form.dates, index);
      if (this.form.dates.length === 0) {
        this.form.dates.push({
          begin_time: null,
          end_time: null,
          date: null,
          require: null,
        });
      }
    },
    addQuestionRow() {
      this.form.questions.push(BLANK_QUESTION());
    },
    removeQuestionRow(index) {
      Vue.delete(this.form.questions, index);
      if (this.form.questions.length === 0) {
        this.form.questions.push(BLANK_QUESTION());
      }
    },
  },
  computed: {
    numericalRules,
    requiredRules,
    requiredRulesClient,
  },
  mounted() {
    this.showEditor = true;
  },
};
</script>
<style scoped>
.upload__image__container {
  position: relative;
  width: auto;
  background-color: #e6e6e6;
  border: 1px dotted var(--color-gray) !important;
  border-radius: 10px;
}
.btn-remove,
.btn-add {
  font-family: "Montserrat-regular";
  font-size: 14px;
}
.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
}
.checkbox-label {
  font-family: "montserrat-light";
  color: var(--color-black);
  text-transform: lowercase;
  font-size: 20px;
  margin-top: 5px;
}
.chip__value {
  font-family: "montserrat-light";
  font-size: 14px;
}
</style>
<style>
.checkbox--check
  .v-input__control
  .v-input__slot
  .v-input--selection-controls__input
  .v-icon {
  font-size: 30px !important;
}
</style>
