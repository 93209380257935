<template>
  <basic-modal
    ref="modalResponse"
    @open="setData"
    width="1200"
    transition="slide-x-transition"
  >
    <template #header>
      <divider
        title="Response Details"
        sub-title="The DETAILS YOU NEED"
        size="large"
      ></divider>
    </template>
    <template #default>
      <div class="mt-4 px-10 pr-13">
        <SectionTitle title="response" />
        <div class="mt-2">
          <v-row>
            <v-col cols="12" sm="3">
              <RequestDetailsInformation
                title="EXTRA JOB"
                subTitle="yes"
                class="ma-0"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <RequestDetailsInformation
                title="model nearest station"
                :subTitle="details.nearest_station"
                class="ma-0"
              />
            </v-col>
          </v-row>
        </div>
        <div class="mt-1">
          <v-row>
            <v-col cols="12">
              <div class="request__details__information d-flex">
                <div class="d-block ml-1">
                  <div class="request__details__information--title">
                    Shooting date
                  </div>
                  <div class="request__details__information--sub-title">
                    <v-chip
                      v-for="date in details.dates"
                      :key="date.id"
                      class="dates__chip py-3 mr-1"
                      :color="
                        date.status === 'ng' ? '#feeaed' : 'grey lighten-3'
                      "
                      :text-color="date.status === 'ng' ? '#ee3552' : 'black'"
                      x-small
                      label
                      >{{ date.date }}, {{ date.begin_time }} to
                      {{ date.end_time }}&nbsp;- &nbsp;
                      <span class="dates__chip--range">
                        {{
                          date.status === "1st_keep"
                            ? "1ST"
                            : date.status === "2nd_keep"
                            ? "2ND"
                            : "NG"
                        }}</span
                      >
                    </v-chip>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col>
              <RequestDetailsInformation
                class="mr-0"
                title="Comment"
                :subTitle="details.comment"
              />
            </v-col>
          </v-row>
        </div>
        <div class="mt-1">
          <v-row>
            <v-col>
              <RequestDetailsInformation
                class="ma-0"
                title="changes"
                :subTitle="details.change_image_comment"
              />
            </v-col>
          </v-row>
        </div>
        <div
          class="mt-11"
          v-if="details.question_values && details.question_values.length > 0"
        >
          <SectionTitle title="questions answer" />
          <div class="mt-2">
            <v-row>
              <v-col
                cols="12"
                v-for="question in details.question_values"
                :key="question.key"
                ><RequestDetailsInformation
                  :title="question.name"
                  :subTitle="
                    Array.isArray(question.value)
                      ? question.value.join(',')
                      : question.value
                  "
                  class="ma-0"
              /></v-col>
            </v-row>
          </div>
        </div>
        <!-- files -->
        <template v-if="details.galleries && details.galleries.length">
          <div class="mt-11">
            <SectionTitle title="files" />
          </div>
          <div class="d-flex flex-wrap mt-2">
            <div
              v-for="gallery in details.galleries"
              :key="gallery.id"
              class="mr-1"
            >
              <div class="wa__basic__info__image">
                <a :href="`${gallery.thumbnail}`" target="_blank">
                  <div
                    class="wa__basic__info__image--image"
                    :style="{ backgroundImage: `url(${gallery.thumbnail})` }"
                  ></div>
                </a>
              </div>
            </div>
          </div>
        </template>
        <!-- model -->
        <div class="mt-12 pt-1">
          <SectionTitle title="model" />
          <div class="d-flex align-center justify-space-between mt-2 mb-10">
            <ul class="d-flex mb-0 pl-0">
              <RequestDetailsInformation
                title="full name"
                :subTitle="details.user.name"
              />
              <RequestDetailsInformation
                title="gender"
                :subTitle="genderUser"
              />
              <RequestDetailsInformation title="Total jobs" subTitle="12" />
              <RequestDetailsInformation
                title="phone number"
                :subTitle="details.user.cell_phone"
              />
            </ul>
            <div>
              <router-link
                :to="{
                  name: 'modelDetailOffer',
                  params: { id: details.user.id, locale: $_getlocaleParam() },
                }"
                class="text-decoration-none"
              >
                <block-button
                  text="KNOW MORE"
                  icon-right="WMi-right-open"
                  @click.native="closeModals"
                  class="btn-more site__button width-auto"
                  height="31"
                ></block-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-3 pt-2 pb-2">
        <block-button
          class="btn__size__14 btn__modal--assign mr-5 site__button width-auto"
          text="IT'S ALLRIGHT, THANK YOU"
          @click.native="$_closeModal()"
          height="30"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>

<script>
import SectionTitle from "../../Global/Section/SectionTitle.vue";
import RequestDetailsInformation from "../../Global/Section/RequestDetailsInformation.vue";
import JobOfferRepository from "@/abstraction/repository/jobOfferRepository";
let jobOfferRepository = new JobOfferRepository();
export default {
  name: "modal_offer_response_detail",
  components: {
    SectionTitle,
    RequestDetailsInformation,
  },
  data() {
    return {
      responseId: null,
      details: {
        user: {},
        dates: [],
        galleries: [],
      },
    };
  },
  methods: {
    async loadDetailsData() {
      this.details = await jobOfferRepository.showJobOfferResponse(
        this.responseId
      );
    },
    setData({ model }) {
      this.responseId = model.id;
      this.loadDetailsData();
    },
    closeModals() {
      this.$_closeModal();
      this.$refs.modalResponse.$_closeModal();
    },
  },
  computed: {
    genderUser() {
      if (this.details.user.gender_id) {
        switch (this.details.user.gender_id) {
          case 1:
            return "male";
          case 2:
            return "female";
          case 3:
            return "other";
        }
      }
      return "";
    },
  },
};
</script>

<style scoped>
/* basic info image */
.wa__basic__info__image {
  width: 163px;
  height: 150px;
  padding: 5px;
  border: 1px solid var(--color-light-gray);
  border-radius: 6px;
}
.wa__basic__info__image--image {
  height: 100%;
  background-size: cover;
  background-position: center;
}
.title {
  color: var(--color-light-gray);
  font-family: "Montserrat-regular" !important;
  font-size: 9px !important;
  height: 22px;
}

.description {
  font-size: 14px;
  font-family: "Montserrat-bold" !important;
}

.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
}

.btn-more {
  font-family: "montserrat-regular", sans-serif;
  font-size: 11px;
  padding: 13px 10px !important;
}
.request__details__information::before {
  height: 24px;
  background-color: var(--color-light-gray);
  content: "";
  display: block;
  width: 1px;
  margin: 7px 2px 0 0;
}
.request__details__information--title {
  color: var(--color-smoky);
  font-size: 12px;
  font-family: "Montserrat-ExtraLight";
  text-transform: uppercase;
}
.request__details__information--sub-title {
  font-size: 15px;
  font-family: "Montserrat";
  color: var(--color-black);
  position: relative;
  top: -0.3rem;
}
.dates__chip {
  font-size: 14px;
  font-family: "Montserrat-light";
}
.dates__chip--range {
  font-family: "Montserrat";
}
</style>
