<template>
  <basic-modal @open="setData" width="800" transition="slide-x-transition">
    <template #header>
      <divider title="Send Email" sub-title="lets do business" size="small"></divider>
    </template>
    <template #default>
      <div class="mt-4 px-12">
        <v-row>
          <v-col cols="12" md="8">
            <v-autocomplete
              label="email template"
              color="SonicSilver"
              class="mt-0 pt-0"
              :items="[...getUserTemplates, ...{ id: -1, title: 'no template' }]"
              item-value="id"
              item-text="title"
              outlined
              dense
              return-object
              v-model="form.email_template"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field
              label="subject"
              color="black"
              class="mt-0 pt-0"
              outlined
              dense
              v-model="subject"
              :disabled="form.email_template.id !== -1"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-textarea
          label="message"
          color="SonicSilver"
          class="mt-0 pt-0"
          outlined
          dense
          v-model="message"
          :disabled="form.email_template.id !== -1"
        ></v-textarea>
        <!-- <div class="d-flex align-center">
          <span class="variables text-uppercase">Variables: </span>
          <div class="ml-2">
            <v-chip
              class="variable__chip ml-2"
              color="#e6e6e66b"
              text-color="black"
              label
              small
              >s</v-chip
            >
            <v-chip
              class="variable__chip ml-2"
              color="#e6e6e66b"
              text-color="black"
              label
              small
              >s</v-chip
            >
          </div>
        </div> -->
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-7 pt-2 pb-3 d-flex justify-space-between align-items-center w-100">
        <block-button
          class="btn__modal--cancel btn__size__18 pl-0"
          text="cancel"
          icon="WMi-cancel-linear"
          text-mode="text"
          @click.native="$_closeModal()"
        ></block-button>
        <block-button
          height="30"
          class="btn__modal--assign site__button width-auto ml-5 btn__size__16"
          text="send the email"
          :loading="requestLoading"
          @click.native="send"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "modal_sendEmail",
  props: ["job_offer_id"],
  data() {
    return {
      form: {
        email_template: { id: -1, title: "no template" },
      },
      requestLoading: false,
    };
  },
  computed: {
    ...mapGetters("template", ["getUserTemplates"]),
    subject: {
      get() {
        if (this.form.email_template.id === -1) {
          return this.form.subject;
        } else {
          return this.form.email_template.title;
        }
      },
      set(value) {
        if (this.form.email_template.id === -1) {
          this.form.subject = value;
        } else {
          this.form.subject = null;
        }
      },
    },
    message: {
      get() {
        if (this.form.email_template.id === -1) {
          return this.form.message;
        } else {
          return this.form.email_template.message;
        }
      },
      set(value) {
        if (this.form.email_template.id === -1) {
          this.form.message = value;
        } else {
          this.form.message = null;
        }
      },
    },
  },
  methods: {
    ...mapActions("template", ["loadTemplates"]),
    setData({ model }) {
      if (model) {
        this.form = { ...this.form, ...model };
      }
    },
    ...mapActions("jobOffer", ["sendEmail"]),
    async send() {
      try {
        this.requestLoading = true;
        const response = await this.sendEmail({
          job_offer_id: this.job_offer_id,
          response: this.form,
        });
        this.$_closeModal();
        this.$_openModal("emailConfirmation", { model: response });
      } catch (e) {
        return e;
      } finally {
        this.requestLoading = false;
      }
    },
  },
  created() {
    this.loadTemplates();
  },
};
</script>
<style scoped>
.title {
  color: var(--color-light-gray);
  font-family: "Montserrat-regular" !important;
  font-size: 9px !important;
  height: 22px;
}
.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
}
.variables {
  font-family: "montserrat-light";
  font-size: 18px;
}
.variable__chip {
  font-family: "montserrat-light";
}
</style>
