<template>
  <basic-modal @open="setData" @close="closeModal" width="90%" transition="slide-x-transition">
    <template #header>
      <divider
        title="Offer Responses"
        sub-title="The models Responses"
        size="large"
      ></divider>
    </template>
    <template #default>
      <div class="mt-10 px-6 px-13">
        <div class="position-relative overflow-hidden mb-5">
          <div class="md-title">Process Timeline</div>
          <div class="md-stepper-horizontal">
            <div class="md-step active">
              <span class="number-step">1</span>
              <div class="md-step-circle"><span></span></div>
              <div class="md-step-title">Model acceptance</div>
              <div class="md-step-bar-left"></div>
              <div class="md-step-bar-right"></div>
            </div>
            <div class="md-step active">
              <span class="number-step">2</span>
              <div class="md-step-circle"><span></span></div>
              <div class="md-step-title">Admin verify</div>
              <!-- there is optional option for describe title -->
              <!-- <div class="md-step-optional"></div> -->
              <div class="md-step-bar-left"></div>
              <div class="md-step-bar-right"></div>
            </div>
            <div class="md-step active">
              <span class="number-step">3</span>
              <div class="md-step-circle"><span></span></div>
              <div class="md-step-title">Client approve</div>
              <div class="md-step-bar-left"></div>
              <div class="md-step-bar-right"></div>
            </div>
            <div class="md-step active">
              <span class="number-step">4</span>
              <div class="md-step-circle"><span></span></div>
              <div class="md-step-title">admin final approve</div>
              <div class="md-step-bar-left"></div>
              <div class="md-step-bar-right"></div>
            </div>
          </div>
        </div>
        <div class="">
          <ResponseSelectedAllItems v-if="getAllResponseSelected" />
          <ResponseSelectedItem
            v-if="!getAllResponseSelected && getResponseSelected.length"
          />
        </div>
        <div class="mt-8">
          <v-row>
            <v-col cols="2">
              <ResponseFilter :job_offer_id="jobOfferId" />
            </v-col>
            <v-col cols="10">
              <div class="pl-5">
                <ResponseItems :job_offer_id="jobOfferId" />
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-3 pt-2 pb-2 d-flex justify-space-between align-items-center w-100">
        <block-button
          class="btn__size__18 cancel__button ml-5 width-auto"
          height="30"
          text="send Model Select Page to admin (Testing Purposes)"
          text-mode="text"
          color="black"
          @click.native="sendToAdmin"
        />
        <block-button
          class="btn__size__14 btn__modal--assign mr-5 site__button width-auto"
          text="IT'S ALLRIGHT, THANK YOU"
          @click.native="$_closeModal()"
          height="30"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import ResponseFilter from "./Components/Filter.vue";
import ResponseItems from "./Components/Items.vue";
import ResponseSelectedItem from "./Components/SelectedItems.vue";
import ResponseSelectedAllItems from "./Components/SelectedAllItmes.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "modal_offer_response",
  components: {
    ResponseFilter,
    ResponseItems,
    ResponseSelectedItem,
    ResponseSelectedAllItems,
  },
  data() {
    return {
      jobOfferId: null,
    };
  },
  methods: {
    ...mapActions('jobOffer', ['clearResponseSelected','sendLinkToClient']),
    setData({ model }) {
      this.jobOfferId = model.id;
    },
    closeModal(){
      this.clearResponseSelected()
      this.DESTROY_HISTORY_RESPONSE()
    },
    sendToAdmin(){
      this.sendLinkToClient({ job_offer_id: this.jobOfferId,send_to_admin:true });
    },
    ...mapMutations('jobOffer', ['DESTROY_HISTORY_RESPONSE']),
  },
  computed: {
    ...mapGetters("jobOffer", ["getResponseSelected", "getAllResponseSelected"]),
  },
};
</script>
<style scoped>
.title {
  color: var(--color-light-gray);
  font-family: "Montserrat-regular" !important;
  font-size: 9px !important;
  height: 22px;
}
.description {
  font-size: 14px;
  font-family: "Montserrat-bold" !important;
}
.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
}
/* steper */
.md-title {
  font-size: 20px;
  font-family: "Montserrat-Light";
  width: min-content;
  position: absolute;
  top: 17%;
  /* margin-left: 20px; */
  text-transform: uppercase;
  color: var(--color-black);
}
.md-stepper-horizontal {
  display: table;
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
}
.md-stepper-horizontal .md-step {
  display: table-cell;
  position: relative;
  padding: 24px 196px;
}
.md-stepper-horizontal .md-step .number-step {
  font-size: 20px;
  font-family: "Montserrat-regular";
  color: var(--color-black);
  position: absolute;
  top: 0;
  left: 49%;
}
.md-stepper-horizontal .md-step:active {
  border-radius: 15% / 75%;
}
.md-stepper-horizontal .md-step:first-child:active {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.md-stepper-horizontal .md-step:last-child:active {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.md-stepper-horizontal .md-step:first-child .md-step-bar-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-right {
  display: none;
  /* right: 0;
  left: 50%;
  margin-left: 7px; */
}
.md-stepper-horizontal .md-step .md-step-circle {
  width: 15px;
  height: 15px;
  margin: 0 auto;
  background-color: var(--color-black);
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}
.md-stepper-horizontal.green .md-step.active .md-step-circle {
  background-color: #00ae4d;
}
.md-stepper-horizontal.orange .md-step.active .md-step-circle {
  background-color: var(--color-black);
}
.md-stepper-horizontal .md-step.active .md-step-circle {
  background-color: var(--color-black);
}
.md-stepper-horizontal .md-step .md-step-title {
  margin-top: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  font-family: "Montserrat-Light";
  width: min-content;
  margin: 0 auto;
}
.md-stepper-horizontal .md-step .md-step-title,
.md-stepper-horizontal .md-step .md-step-optional {
  text-align: center;
  color: rgba(0, 0, 0, 0.26);
}
.md-stepper-horizontal .md-step.active .md-step-title {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
}
.md-stepper-horizontal .md-step .md-step-optional {
  font-size: 12px;
}
.md-stepper-horizontal .md-step.active .md-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
  position: absolute;
  top: 31px;
  height: 1px;
  border-top: 2px solid #707070;
}
.md-stepper-horizontal .md-step .md-step-bar-right {
  right: 0;
  left: 50%;
  /* margin left for change space between circle and line */
  margin-left: 8px;
}
.md-stepper-horizontal .md-step .md-step-bar-left {
  left: 0;
  right: 50%;
  /* margin-right: 20px; */
  margin-right: 8px;
}
</style>
