<template>
  <v-form class="mb-3 h-100" @submit.prevent="submit">
    <SectionTitle title="filters" />
    <hr class="mt-0" />
    <v-row>
      <v-col cols="12" class="pt-4">
        <div class="h-30">
          <v-text-field
            label="model name"
            class="no-error-msg"
            color="SonicSilver"
            v-model="filter.user_name.val"
            outlined
            dense
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          class="pt-2 no-error-msg"
          :items="responses"
          item-value="id"
          item-text="name"
          label="response"
          color="SonicSilver"
          v-model="filter.job_offer_response_status_id.val"
          outlined
          dense
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          class="no-error-msg pt-0"
          :items="shooting_date"
          label="shooting date"
          color="SonicSilver"
          v-model="shooting_date_val"
          outlined
          dense
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="4" class="pr-0">
        <block-button
          height="30"
          class="w-100 cancel__button br__mode btn__size__14"
          text="clear"
          color="black"
          @click.native="clearFilter"
        ></block-button>
      </v-col>
      <v-col cols="8" class="pl-1">
        <block-button
          height="30"
          class="w-100 site__button width-auto add__mode btn__size__18"
          type="submit"
          text="execute"
        ></block-button>
      </v-col>
    </v-row>
    <v-row class="mt-1">
      <v-col class="py-1" cols="12">
        <block-button
          height="30"
          class="w-100"
          color="black"
          text="send to client"
          @click.native="sendToClient"
        ></block-button>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-1" cols="12">
        <block-button
          height="30"
          class="w-100"
          color="cyan"
          bg-color="bgCyan"
          text="approve selected"
          @click.native="approveMsg"
        ></block-button>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-1" cols="12">
        <block-button
          height="30"
          class="w-100"
          color="red"
          bg-color="bgLightPink"
          text="reject selected"
          @click.native="rejectMsg"
        ></block-button>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import SectionTitle from "../../../Global/Section/SectionTitle.vue";
import toast from "@/utils/toast";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { cloneDeep } from "lodash";
const defaultFilter = {
  shooting_date: {
    type: "in",
    val: null,
    name: "shooting date",
  },
  user_name: {
    type: "like",
    val: null,
    name: "model name",
  },
  job_offer_response_status_id: {
    type: "in",
    val: null,
    name: "response status",
    values: [],
  },
};
export default {
  name: "modal_filter",
  props: ["job_offer_id"],
  data() {
    return {
      filter: {},
      shooting_date: ["1st keep", "2nd keep", "NG"],
      responses: [
        {
          id: 1,
          name: "Accepted",
        },
        {
          id: 2,
          name: "Rejected",
        },
        {
          id: 3,
          name: "Accepted By Admin",
        },
        {
          id: 4,
          name: "Rejected By Admin",
        },
        {
          id: 5,
          name: "Accepted By Client",
        },
        {
          id: 6,
          name: "Rejected By Client",
        },
      ],
    };
  },
  components: {
    SectionTitle,
  },
  methods: {
    approveMsg(){
      toast.question(this.$_trans("toast.approve_job_offer_msg"), this.$_trans("toast.approve_job_offer_title"),() => {
          this.changeStatus(3);
        })
    },
    rejectMsg(){
      toast.question(this.$_trans("toast.reject_job_offer_msg"), this.$_trans("toast.reject_job_offer_title"),() => {
          this.changeStatus(4);
        })
    },
    sendToClient() {
      toast.s_question(
        {
          message: this.$_trans("toast.send_to_client_response_msg"),
          title:this.$_trans("toast.send_to_client_response_title"),
          overlay: true,
          position: "center",
          timeout: false,
          theme: "dark",
          backgroundColor: "white",
          titleColor: "black",
          icon: "",
          iconColor: "black",
          messageColor: "black",
        },
        () => {
          this.sendLinkToClient({ job_offer_id: this.job_offer_id });
        }
      );
    },
    changeStatus(statusId) {
      this.changeStatusSelected({status_id:statusId,job_offer_id: this.job_offer_id});
    },
    ...mapMutations("jobOffer", ["SET_RESPONSE_FILTER","SET_IS_FILTERED_RESPONSE"]),
    ...mapActions("jobOffer", [
      "loadResponses",
      "loadResponseStatuses",
      "sendLinkToClient",
      "changeStatusResponse",
      "changeStatusSelected",
    ]),
    submit() {
      this.setFilterValues();
      this.SET_IS_FILTERED_RESPONSE(true);
      this.SET_RESPONSE_FILTER(cloneDeep(this.filter));
      this.loadResponses(this.job_offer_id);
    },
    setFilterValues() {
      this.filter.job_offer_response_status_id.values = this.responses;
    },
    clearFilter() {
      this.filter = cloneDeep(defaultFilter);
      this.SET_RESPONSE_FILTER(cloneDeep(this.filter));
      this.loadResponses(this.job_offer_id);
      this.SET_IS_FILTERED_RESPONSE(false);
    },
    loadPastFilter(){
      this.filter = cloneDeep({...defaultFilter , ...this.getFiltersResponse})
    }
  },
  computed: {
    ...mapGetters("jobOffer", ["getResponseStatuses", "getResponseSelected","getFiltersResponse"]),
    shooting_date_val: {
      get() {
        return this.value;
      },
      set(value) {
        this.filter.shooting_date.val = value;
        this.filter.shooting_date.value = value;
      },
    },    
  },
  created() {
    this.loadResponseStatuses();
    this.loadPastFilter();
  },
};
</script>
<style scoped>
.h-30 {
  height: 30px;
}
</style>
