<template>
  <v-row
    v-if="getResponseNotSelected"
    class="section-bordered theme-cyan ma-0 wrap"
  >
    <list class="wa__large__page w-100">
      <v-expansion-panels v-model="panel" :readonly="readonly">
        <v-expansion-panel>
          <v-expansion-panel-header hide-actions>
            <div class="d-flex justify-space-between align-center">
              <div>
                <!-- <v-badge color="red" right>
                                <template v-slot:badge>
                                    <span> {{getResponseNotSelected.length}} </span>
                                </template>
                                <h3> models not selected </h3>
                                <div class="gray--text mt-2"> choice action
                                </div>
                            </v-badge> -->

                <v-chip
                  class="chip__models__not__selected w-100"
                  label
                  text-color="#EE3552"
                  data-target="#responseNotSelected"
                  aria-labelledby="responseNotSelected"
                >
                  <div>
                    ALL RESPONSES SELECTED EXECPT
                    <span>{{ getResponseNotSelected.length }}</span> response
                  </div>
                  <div class="d-flex justify-space-between">
                    <v-icon
                      small
                      color="#EE3552"
                      class="WMi-down-open"
                    ></v-icon>
                  </div>
                </v-chip>
              </div>
              <div class="justify-content-end">
                <circle-button
                  icon="WMi-mail-alt"
                  color="black"
                  @click.native="
                    $_openModal('sendEmail', { model: getResponseNotSelected })
                  "
                />
                <circle-button
                  icon="WMi-trash"
                  color="red"
                  bg-color="bgLightPink"
                />
                <!-- <block-button
                height="30"
                class="btn__modal--assign ml-5"
                text="send email"
                icon="WMi-message-reply-text"
                color="red"
                bg-color="bgRed"
                @click.native="
                  $_openModal('sendEmail', { model: getResponseNotSelected })
                "
              ></block-button> -->
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-chip
              class="ma-1"
              v-for="(model, i) in getResponseNotSelected"
              v-bind:key="i"
              @click:close="removeFromNotSelected(model)"
              close
              close-icon="WMi-cancel"
            >
              {{ model.user.name }}
            </v-chip>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </list>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: function () {
    return {
      panel: [1, 1],
      readonly: false,
    };
  },
  computed: {
    ...mapGetters("jobOffer", ["getResponseNotSelected"]),
  },
  methods: {
    ...mapActions("jobOffer", ["removeFromNotSelected"]),
  },
};
</script>

<style scoped lang="scss">
.Filters {
  margin: 20px 0px 10px 0px;
}
</style>
<style>
.chip__models__not__selected {
  height: 42px !important;
  width: 798px !important;
}
.chip__models__not__selected {
  background-color: #ee355113 !important;
}
.chip__models__not__selected .v-chip__content {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  padding: 0 20px !important;
}
.chip__models__not__selected .v-chip__content div {
  font-family: "montserrat-regular" !important;
  font-size: 17px;
  letter-spacing: 2px !important;
}
.chip__models__not__selected .v-chip__content div span {
  font-family: "montserrat";
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
.v-expansion-panel-header {
  padding: 10px !important;
}
</style>