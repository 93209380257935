<template>
  <basic-modal
    @open="openModal"
    width="700"
    transition="slide-x-transition"
    not-footer="true"
  >
    <template #header>
      <divider
        title="change status"
        sub-title="THE REQUEsST STATUS"
        size="small"
      ></divider>
    </template>
    <template #default>
      <div class="px-5 mt-3 pb-7">
        <status-request :items="getStatuses" v-model="role" />
      </div>
    </template>
  </basic-modal>
</template>
<script>
import StatusRequest from "../../Global/Section/StatusRequest.vue";
import { mapActions } from "vuex";
export default {
  name: "modal_changeStatus",
  components: {
    StatusRequest,
  },
  methods: {
    ...mapActions("jobOffer", ["changeStatusManager"]),
    openModal({ model }) {
      this.pastStatus = model.status;
      this.role = this.pastStatus;
      this.form = { ...model };
    },
  },
  data: () => ({
    form: {},
    role: null,
    pastStatus: null,
    getStatuses: [
      {
        id: "onhold",
        name: "onhold",
        description: "onhold",
        color: "cyan",
      },
      {
        id: "ongoing",
        name: "ongoing",
        description: "ongoing",
        color: "cyan",
      },
      {
        id: "canceled",
        name: "canceled",
        description: "canceled",
        color: "red",
      },
      {
        id: "closed",
        name: "closed",
        description: "closed",
        color: "red",
      },
    ],
  }),
  watch: {
    role: {
      async handler(newStatus) {
        try {
          if (newStatus !== this.pastStatus) {
            this.form.newStatus = newStatus
            await this.changeStatusManager(this.form);
            this.$_closeModal();
          }
        } catch (e) {
          return e;
        }
      },
      deep: true,
    },
  },
};
</script>
