<template>
  <list title="offers" class="wa__large">
    <template #header-btn>
      <block-button
        text="add"
        icon="WMi-plus-linear"
        height="37"
        class="px-10 btn__size__18 site__button add__mode width-200 mt-n1"
        @click.native="$_openModal('new_offer')"
      ></block-button>
    </template>
    <hr class="mt-0" />
    <v-row class="px-7 pb-1 d-none d-md-flex">
      <v-col cols="4" class="wa__offer__title--title pl-12"
        ><span class="pl-4">TITLE / date</span></v-col
      >
      <v-col cols="4" class="wa__offer__title--title pl-3"><span>status</span></v-col>
      <v-col cols="4" class="wa__offer__title--title text-right pr-16"
        ><span>controls</span></v-col
      >
    </v-row>
    <data-iterator
      :items="getJobOffers"
      :is-filtered="false"
      @pagination="changePagination"
      :pagination="getPaginationJobOffer"
      :loading="getLoadingOffer"
    >
      <template v-slot:loading>
        <loading />
      </template>
      <template #no-items>
        <no-items />
      </template>
      <template #no-results>
        <no-items />
      </template>
      <template v-slot:items="{ item }">
        <OfferItem :key="item.id" :offer="item" class="mb-2" />
      </template>
    </data-iterator>
  </list>
</template>
<script>
import OfferItem from "./Item.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  components: {
    OfferItem,
  },
  computed: {
    ...mapGetters("jobOffer", [
      "getJobOffers",
      "getPaginationJobOffer",
      "getLoadingOffer",
    ]),
  },
  methods: {
    ...mapActions("jobOffer", ["loadJobOffers"]),
    ...mapMutations("jobOffer", ["SET_PAGINATION_JOB_OFFERS"]),
    changePagination(pagination) {
      if (this.getPaginationJobOffer.page !== pagination.page) {
        this.SET_PAGINATION_JOB_OFFERS(pagination);
        this.loadJobOffers();
      }
    },
  },
  created() {
    this.loadJobOffers();
  },
};
</script>
<style scoped>
.wa__offer__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  height: 16px;
}
.wa__offer__title--title {
  font-family: "Montserrat";
  font-size: 0.6rem;
  color: var(--color-light-gray);
  text-transform: uppercase;
  letter-spacing: 1px;
}
.add__offer {
  position: absolute;
  bottom: 30px;
  right: 60px;
}
.add__new__offer {
  font-family: "Montserrat-ExtraLight", sans-serif;
}
</style>
