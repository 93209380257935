<template>
  <v-form class="mb-3 h-100" @submit.prevent="submit">
    <list title="filters" :filter="true" class="h-100">
      <hr class="mt-0" />
      <v-row>
        <v-col cols="12" class="pt-4">
          <!-- <div class="h-30"> -->
          <v-text-field
            label="offer title"
            class="no-error-msg"
            color="SonicSilver"
            v-model="filter.title.val"
            outlined
            dense
          ></v-text-field>
          <!-- </div> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0">
          <v-autocomplete
            :items="getStatuses"
            class="pt-2 no-error-msg"
            item-value="id"
            item-text="name"
            label="status"
            color="SonicSilver"
            v-model="filter.status.val"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-2">
          <!-- <div class="h-30"> -->
          <date-input
            class="no-error-msg"
            label="date, after"
            color="SonicSilver"
            v-model="filter.end_date.val"
            outlined
            dense
          ></date-input>
          <!-- </div> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-1">
          <!-- <div class="h-30"> -->
          <date-input
            label="date, before"
            color="SonicSilver"
            class="no-error-msg"
            v-model="filter.created_at.val"
            outlined
            dense
          ></date-input>
          <!-- </div> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="pr-0">
          <block-button
            height="30"
            class="w-100 cancel__button br__mode btn__size__14"
            text="clear"
            color="black"
            @click.native="clearFilter"
          ></block-button>
        </v-col>
        <v-col cols="8 pl-1">
          <block-button
            height="30"
            class="w-100 site__button width-auto add__mode btn__size__18"
            type="submit"
            text="execute"
          ></block-button>
        </v-col>
      </v-row>
    </list>
  </v-form>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { cloneDeep } from "lodash";
const defaultFilter = {
  created_at: {
    type: "like",
    val: null,
  },
  end_date: {
    type: "like",
    val: null,
  },
  status: {
    type: "in",
    val: null,
  },
  title: {
    type: "like",
    val: null,
  },
};
export default {
  name: "offer_filter",
  data() {
    return {
      filter: {},
      getStatuses: ["onhold", "ongoing", "canceled", "closed"],
    };
  },
  methods: {
    ...mapMutations("jobOffer", ["SET_FILTER_JOB_OFFER"]),
    ...mapActions("jobOffer", ["loadJobOffers", "loadResponseStatuses"]),
    submit() {
      this.SET_FILTER_JOB_OFFER(this.filter);
      this.loadJobOffers();
    },
    clearFilter() {
      this.filter = cloneDeep(defaultFilter);
      this.SET_FILTER_JOB_OFFER(cloneDeep(this.filter));
      this.loadJobOffers();
    },
    loadPastFilter() {
      this.filter = cloneDeep({ ...defaultFilter, ...this.getFiltersOffer });
    },
  },
  computed: {
    ...mapGetters("jobOffer", ["getFiltersOffer"]),
  },
  created() {
    this.loadResponseStatuses();
    this.loadPastFilter();
  },
};
</script>
<style scoped>
.h-30 {
  height: 30px;
}
</style>
